<template>
  <div class="pageContainer targetPage flexCloumn">
    <el-row class="searchBar flexBetween" style="flex-wrap: wrap;width:100%">
      <div class="flexStart flex_1" style="flex-wrap:wrap;">

        <el-input placeholder="学号或学生姓名" v-model="searchForm.stu_str" size="small" class="input-with-select"
          style="width:200px" clearable></el-input>
        <el-input placeholder="班级名称" v-model="searchForm.class_name" size="small" class="input-with-select"
          style="width:200px;margin-left: 10px" clearable></el-input>
        <el-date-picker v-model="dateArray" size="small" type="datetimerange" :picker-options="pickerOptions" range-separator="至"
          start-placeholder="访谈开始日期" end-placeholder="访谈结束日期" align="right" class="input-with-select"
          style="width:400px;margin-left: 10px" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>

        <el-button icon="el-icon-search" style="margin-left:10px" size="small" type="primary"
          @click="() => { page.current_page = 1; getList()}">搜索</el-button>

        <el-button icon="el-icon-plus" style="margin-left:10px" size="small" type="primary"
          @click="saveJh">添加学生访谈记录</el-button>
      </div>

      <div class="flexStart"
        style="color: #3378fa;font-size: 14px;font-weight: 700;margin: 0 5px 0 30px;cursor: pointer;">

        <el-button v-if="$store.state.config.sync_data" size="mini" icon="el-icon-refresh" @click="tongbuJava"
          type="primary" plain style="margin-left: 10px;">迁移老版本</el-button>
      </div>
    </el-row>
    <div class="dividerBar"></div>
     <el-table v-tableFit :data="DataList" height="100%" size="small"  stripe border style="width:fit-content;width:100%;">
      <el-table-column type="index" width="55" label="序号"></el-table-column>
      <el-table-column prop="stu_code" label="学号" width="135"></el-table-column>
      <el-table-column prop="stu_name" label="学生姓名" width="150"></el-table-column>
      <el-table-column prop="class_name" label="班级名称" width="150"></el-table-column>
      <el-table-column prop="parent_name" label="家长姓名" width="150"></el-table-column>
      <el-table-column prop="contact_type" label="访谈方式" width="100">
        <template slot-scope="scope">
          <div v-if="scope.row.contact_type == item.id" v-for="(item, idx) in ftfsOptions" :key="idx" :label="item.name"
            :value="item.id" filterable:true>{{ item.name }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="contact_time" label="访谈时间" min-width="200"></el-table-column>
      <!--         <el-table-column prop="content" label="访谈内容" min-width="200">
          <template slot-scope="scope">
            <div style="overflow:hidden;text-overflow:ellipsis;white-space:nowrap;">{{ scope.row.content }}</div>
          </template>
        </el-table-column>-->
      <el-table-column prop="create_name" label="创建人" min-width="150"></el-table-column>
      <el-table-column prop="create_date" label="创建时间" min-width="200"></el-table-column>
      <el-table-column fixed="right" label="操作" width="120">
        <template slot-scope="scope">
          <el-tag class="pointer" type="success" size="mini" v-if="$store.state.teacher.id == scope.row.teacher_id"
            @click="viewInfo(scope.row)">编辑</el-tag>
          <el-tag class="pointer" type="primary" size="mini" v-else @click="viewInfo(scope.row)">详情</el-tag>
          <el-tag v-if="!$store.getters.isGradeLeader(scope.row.class_id)" class="pointer ml10" type="danger" size="mini" @click="deleteJh(scope.row)">删除</el-tag>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @current-change="handlePageChange" @size-change="handleSizeChange" :current-page="page.current_page"
      :page-size="page.per_page" :total="page.count" layout="total, sizes,prev, pager, next"
      :page-sizes="[10, 20, 50, 100]" style="margin-top: 10px;">
    </el-pagination>
    <formdialog v-if="dialogshow" :id="EditItem.id"></formdialog>
  </div>
</template>

<script>
import formdialog from "./formdialog.vue";
import util from "../../utils/util.js";

export default {
  components: {
    formdialog
  },
  data() {
    return {
      dateArray: [],
      dialogshow: false,
      searchForm: {
        stu_str: "",
        class_name: "",
        sdate: "",
        edate: ""
      },
      EditItem: {
        is_draft: 1,
        title1: "",
        id: 0,
        stu_id: 0,
        stu_code: "",
        stu_name: "",
        class_name: "",
        parent_name: "",
        contact_type: "",
        contact_time: "",
        content: "",
        create_by: 0,
        create_name: "",
        sort: 99
      },
      DataList: [],
      ftfsOptions: [
        { id: 1, name: "电话" },
        { id: 2, name: "视频" },
        { id: 3, name: "面谈" },
        { id: 4, name: "QQ" },
        { id: 5, name: "微信" }
      ],
      page: {
        count: 0,
        current_page: 1,
        per_page: 50,
        total_page: 0
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      }
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      this.$http
        .post("/api/stu_liaison_list", {
          page: this.page.current_page,
          pagesize: this.page.per_page,
          stu_str: this.searchForm.stu_str,
          class_name: this.searchForm.class_name,
          sdate: this.dateArray.length > 0 ? this.dateArray[0] : "",
          edate: this.dateArray.length > 0 ? this.dateArray[1] : "",
          biye:""
        })
        .then(res => {
          this.DataList = res.data.data;
          this.page = res.data.page;
        });
    },
    deleteJh(row) {
      this.$confirm("此操作将删除该条数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$http
            .post("/api/stu_liaison_delete", { id: row.id })
            .then(res => {
              this.$message({
                type: "success",
                message: "删除成功!"
              });
            });
          this.getList();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    handlePageChange(page) {
      this.page.current_page = page;
      this.getList();
    },
    handleSizeChange(val) {
      this.page.current_page = 1;
      this.page.per_page = val;
      this.getList()
    },
    addNew() {
      this.EditItem.id = 0;
      this.dialogshow = true;
    },
    saveJh() {
      this.EditItem = {
        is_draft: 1,
        title1: "添加学生访谈记录",
        id: 0,
        stu_id: 0,
        stu_code: "",
        stu_name: "",
        class_name: "",
        parent_name: "",
        contact_type: "",
        contact_time: "",
        content: "",
        create_by: 0,
        create_name: "",
        sort: 99
      };
      this.dialogshow = true;
    },
    viewInfo(row) {
      this.EditItem = { ...row };
      this.EditItem.title1 = "学生访谈记录详情";
      this.dialogshow = true;
    },
    tongbuJava() {
      this.$confirm('请确保JAVA版本已存在？是否继续同步?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let loadingInstance = this.$loading({
          lock: true,
          text: "已发送请求，请稍等...",
          spinner: 'el-icon-loading'
        })
        this.$http.post("/api/sync_contact_info", {}).then(res => {
          loadingInstance.close()
          this.$message.success('后台同步中，请刷新查看')
          this.getList();
        }).catch(err => {
          this.$message.error('同步失败')
          loadingInstance.close()
        })

      }).catch(() => {

      });
    }
  }
};
</script>

<style scoped="scoped">
.spuser {
  display: inline-block;
  border: 1px solid rgb(255, 148, 62);

  padding: 2px;
  margin-right: 10px;
  margin-bottom: 5px;
  border-radius: 4px;
  padding: 4px 10px;
}

.spuser img {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
}
</style>
